<template>
    <div class="sm:mx-0 sm:grid sm:grid-cols-6">
        <div class="sm:col-start-2 sm:col-span-4">
            <div class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50" v-if="loading">
                <span class="opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0" style="top: 50%;">
                    <svg class="animate-spin ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </span>
            </div>

            <div v-else>
                <div class="m-2 ccss--table-open">
                    <div class="content-container checkout-box p-5 radius-anchor">
                        <div class="text-center">
                            <h2 class="text-3xl font-bold mb-3">{{ $t(`tables.open.${paymenttype}.title`) }} {{ table?.attributes.name }}</h2>
                        </div>

                        <div>
                            <div>
                                <div class="text-left">

                                    <template v-if="hourlyRateItem && table.attributes.paymenttype == 'onaccount'">
                                        <template v-if="!table.attributes.activated_at">

                                            <div class="text-lg font-bold mb-2">{{ $t(`tables.open.${paymenttype}.subtitle`) }}</div>

                                            <div class="text-base mb-1">1. {{ $t(`tables.open.plug_and_play.step1`) }}</div>
                                            <div class="text-base mb-1">2. {{ $t(`tables.open.plug_and_play.step2`) }}</div>
                                            <div class="text-base mb-1">3.{{ $t(`tables.open.plug_and_play.step3`) }}</div>
                                            <div class="text-sm italic">{{ $t(`tables.open.plug_and_play.disclaimer`) }}</div> 
                                        </template>


                                        <!-- <basket v-if="table.relationships.lines.length" /> -->

                                        <div class="md:w-2/6 sm:sticky sm:top-20 overflow-auto" 
                                            v-if="table && table.relationships.lines.length > 0">
                                            <div class="content-container checkout-box radius-anchor">
                                                <div class="font-bold">Bon</div>
                                                <div>
                                                    <div class="my-2 pt-0 px-5" v-for="(line, index) in table.relationships.lines" v-bind:key="index">
                                                        <div class="flex justify-start">
                                                            <div class="w-full">
                                                                <div class="flex justify-start">
                                                                    <!-- Quantity -->
                                                                    <div class="flex rounded-sm justify-start mr-3">
                                                                        <div class="text-main pr-3 text-xs">{{ parseFloat(line.attributes.quantity) }}x</div>
                                                                    </div>
                                                                    <div class="flex justify-between w-full">
                                                                        <div class="text-left">
                                                                            <div class="text-sm leading-5" :class="{ 'ml-4 italic': line.attributes.addon }">
                                                                                {{ line.attributes.description }}
                                                                            </div>
                                                                            <div
                                                                                class="italic text-xs my-2"
                                                                                v-for="(kitchen_grocery, grocery_index) in line.attributes.kitchen_groceries"
                                                                                v-bind:key="grocery_index"
                                                                            >
                                                                                {{ kitchen_grocery.description }}: {{ kitchen_grocery.value }}
                                                                            </div>
                                                                        </div>
                                                                        <div class="text-sm">{{ $n(line.attributes.total, currency) }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="bg-shoppingcarttotals pt-0 p-5">
                                                        <div class="my-3 border border-b"></div>

                                                        <div class="flex justify-between font-bold">
                                                            <div>Totaal</div>
                                                            <div>{{ $n(table.attributes.total, currency) }}</div>
                                                        </div>

                                                        <template v-if="hourlyRateConstruct">
                                                            <div class="flex justify-between font-bold">
                                                                <div>Plaatskosten</div>
                                                                <div>{{ rateToPrice(hourlyRateConstruct.totalPrice) }}</div>
                                                            </div>

                                                            <div class="my-3 border border-b"></div>

                                                            <div class="flex justify-between font-bold">
                                                                <div>Totaal</div>
                                                                <div>{{ rateToPrice(hourlyRateConstruct.totalPrice + table.attributes.total) }}</div>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="plug-and-play-info">
                                            <h3 class="font-bold mt-1"
                                                v-if="hourlyRateItem"
                                            >
                                                <span>{{ $t(`tables.open.plug_and_play.hourly_rate`) }}</span><span>  {{ rateToPrice(hourlyRateItem.attributes.price) }}</span>
                                            </h3>
                                            <h3 class="font-medium mt-1"
                                                v-if="table.attributes.activated_at"
                                            >
                                                <span>{{ $t(`tables.open.plug_and_play.activated_at`) }}</span><span> {{ formatDate(table.attributes.activated_at) }}</span>
                                            </h3>

                                            <h3 class="font-medium mt-1"
                                                v-if="table.attributes.customer_info"
                                            >
                                                <span>{{ $t(`tables.open.plug_and_play.activated_by`) }}</span><span>
                                                {{ emailFromCustomerInfo(table.attributes.customer_info) }}</span>
                                            </h3>
                                        </div>
                                    </template>

                                    <template v-else>
                                        <div class="text-lg font-bold mb-2">{{ $t(`tables.open.${paymenttype}.subtitle`) }}</div>
                                        <div class="text-base mb-1">1. {{ $t(`tables.open.${paymenttype}.step1`) }}</div>
                                        <div class="text-base mb-1">2. {{ $t(`tables.open.${paymenttype}.step2`) }}</div>
                                        <div class="text-base mb-1">3.{{ $t(`tables.open.${paymenttype}.step3`) }}</div>
                                        <div class="text-base mb-4">4. {{ $t(`tables.open.${paymenttype}.step4`) }}</div>
                                        <div class="text-sm italic">{{ $t(`tables.open.${paymenttype}.disclaimer`) }}</div> 
                                    </template>

                                </div>
                            </div>

                            <div class="mt-3 relative w-full">
                                <a @click="openTable()" class="cursor-pointer w-full inline-flex justify-center items-center mt-3 px-6 py-3 btn btn-primary text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150">
                                    <template v-if="table.attributes.plug_and_play && !table.attributes.activated_at">
                                        {{ $t(`tables.open.plug_and_play.button_activate`) }}
                                    </template>

                                    <template v-else>
                                        {{ $t(`tables.open.${paymenttype}.button`)}}
                                    </template>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-table-reserved ref="tablereserved" />
        <modal-activate-table ref="tableactivate" @activate="activateTable($event, form)" />
    </div>
</template>

<script>

import * as _api from '../lib/api';
import * as _notification from '../lib/notification';

// libs
import * as _table from '../lib/table';
import * as _transaction from '../lib/transaction';

import ModalActivateTable from '../components/ModalActivateTable';
import moment from 'moment';
import Basket from '../components/Basket';

export default {
    name: 'TableOpen',
    props: ['location_slug', 'table_id'],
    components: {
        // Basket,
        ModalActivateTable,
    },
    data () {
        return {
            invalid: false,
            loading: true,
            hourlyRateItem: null,
            paymenttype: 'direct',
            table_reserved: false,
            submit_action: 'order',
            modal_activate: false,
        }
    },
    async mounted() {
        // reset transaction
        _transaction.clear();
        _transaction.setMethod('tableservice');

        // init the table
        await _table.init(this.table_id);

        // todo: move to _table.js
        if(!this.table) {
            this.tableNotFound()
            return
        }

        const timeslot = await _api.post('timeslots', {
            date: moment().format('YYYY-MM-DD'),
            function: 'reservation',
            area_id: this.table.attributes.area_id,
        });

        if (timeslot) {
            this.hourlyRateItem = timeslot.attributes.reservation_item;
        } 

        this.loading = false;

        this.checkReservationOnTable();

        this.paymenttype = this.table.attributes.paymenttype;
    },

    methods: {
        formatDate(date) {
            return moment(date).format('DD-MM-YYYY HH:mm');
        },
        checkReservationOnTable() {
            let hasReservationFunction = this.table.relationships.location.attributes.functions.reservation;
            if (hasReservationFunction == false) {
                return false;
            }

            if (!this.table.relationships.reservations_today) {
                this.submit_action = 'reserve';
                return false;
            }
        },

        emailFromCustomerInfo(info) {
            return JSON.parse(info).customer_email;
        },

        rateToPrice (rate) {
            return '€ ' +  parseFloat(rate).toFixed(2);
        },

        tableNotFound() {
            this.$router.push({name: 'table.404'});
        },

        async activateTable(form) {
            if (form) {
                const response = await _api.post('table/activate', {
                    table_id: this.table.id,
                    customer_info: form,
                });

                if (response.result == 'success') {
                    console.log('table activated', form);
                     _notification.set('Table activated');

                    
                    //update table data
                    await _table.init(this.table_id);

                    //close activatemodal
                    this.$refs.tableactivate.close();
                }
            }  else {
                console.log('form not correct');
            }
    },

        async openTable() {
            if (this.table.attributes.plug_and_play && !this.table.attributes.activated_at) {
                this.$refs.tableactivate.open();
                return;
            }

            if (this.table.attributes.paymenttype === 'paymentonly') {
                this.$router.push({name: 'checkout.table', params: {
                    location_slug: this.location_slug
                }});
                return;
            }

            this.$router.push({name: 'tableservice', params: {
                location_slug: this.location_slug
            }});
        }
    },

    computed: {
        table() {
            return _table.get();
        },
    }
}
</script>
