<template>
    <div>
        <transition name="blur-background">
            <blur v-if="show" @click="close();">
            </blur>
        </transition>

        <transition name="modal-small">
            <div class="fixed z-100 w-full inset-0 overflow-y-auto ccss--modal-hourly-rate-confirm fixed-modal-wrapper" v-if="show">
                <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
                    <div
                        class="modal inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full radius-anchor"
                        role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                    >
                        <div class="modal-close">
                            <a @click="close()" class="modal-close" aria-label="Close">
                                <svg class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </a>
                        </div>

                        <div class="pt-8 pb-3 p-8">
                            <h1 class="mb-3">{{ $t(`tables.open.plug_and_play.button_activate`) }}</h1>
                            <p class="mb-5">{{ $t(`tables.open.plug_and_play.description`) }} </p>
                                <div class="">
                                    <label for="password"
                                            class="block text-sm font-medium text-gray-700"
                                            :class="{'text-red-500': email_invalid}"
                                    >
                                        {{ $t('fields.email') }}
                                    </label>
                                    <div class="mt-1">
                                        <input v-model="form.customer_email" @focus="invalid = false"
                                                id="customer_email"
                                                name="customer_email" type="customer_email"
                                                autocomplete="current-customer_email"
                                                required
                                                class="appearance-none block w-full px-3 py-2 border rounded-sm shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm"
                                        >
                                    </div>
                                </div>
                        </div>
                            
                        <div class="p-4">
                            <button type="submit"
                                    @click="activate()"
                                    class="btn btn-primary font-bold inline-flex w-full justify-center items-center px-6 py-3 text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150"
                            >
                                {{ $t(`tables.open.plug_and_play.button_activate`) }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>

import * as _api from '../lib/api';
import { Field, Form } from 'vee-validate';
import * as yup from 'yup';
import Blur from "./Blur";

export default {
    name: 'ModalActivateTable',
    // props: 
    components: {
        Blur,
    },
    data () {
        return {
            show: false,
            form: {
                customer_email: '',
            },
            email_invalid: false,
        };
    },
    methods: {
        open() {
            this.show = true;
        },

        close() {
            this.show = false;
        },

        isValidEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return !emailRegex.test(email);
        },

        activate() {
            if (this.form.customer_email) {
                this.email_invalid = this.isValidEmail(this.form.customer_email);

                if (this.email_invalid == false) {
                    this.$emit('activate', this.form)
                }
            } else {
                this.email_invalid = true;
            }
        } 
    },
};
</script>
